import React, { useEffect, useState } from "react";
import Footer from "./NewFooter";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import Header from "./Header";

const CareerResources = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const recaptchaLanguage = Cookies.get("selectedLanguage");

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [t, i18n] = useTranslation("global");
  const currentLanguage = Cookies.get("selectedLanguage") || "en";

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  let captchaKey = Cookies.get("captchaKey");

  const [hoverColor, setHoverColor] = useState(false);

  const handleMouseEnter = () => {
    setHoverColor(true);
  };

  const handleMouseLeave = () => {
    setHoverColor(false);
  };

  const [pageContent, setPageContent] = useState([]);
  const [recaptchaKey, setRecaptchaKey] = useState(captchaKey);
  const [recaptchaVisible, setRecaptchaVisible] = useState(true);

  const handleRecaptchaReset = () => {
    setRecaptchaVisible(false);
    setRecaptchaKey(generateRecaptchaKey());
    setTimeout(() => {
      setRecaptchaVisible(true);
    }, 0); // Using setTimeout to unmount and then mount the ReCAPTCHA
  };

  const generateRecaptchaKey = () => {
    return Math.random().toString(36).substring(2, 15);
  };
  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/page/staticpage/career-tools`,
        { language: currentLanguage }
      );
      setLoading(false);
      setPageContent(response.data.response);
      // document.title = response.data.response.meta_tile;
      
      const metaTitle = document.querySelector('meta[name="title"]');
      // console.log(metaTitle.content, "metaTitle");
      if (metaTitle) {
        metaTitle.content = response.data.response.meta_tile;
      }

      const metaDescription = document.querySelector('meta[name="description"]');
      // console.log(metaDescription.content, "metaDescription");
      if (metaDescription) {
        metaDescription.content = response.data.response.meta_description;
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.content = response.data.response.meta_keyword;
      }
    } catch (error) {
      setLoading(false);
      console.log("Cannot get data of career resource page");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const newErrors = {};

      if (formData.name === "") {
        newErrors.name = t("messageForm.nameRequired");
      }
      if (formData.email === "") {
        newErrors.email = t("messageForm.emailRequired");
      } else if (!isValidEmail(formData.email)) {
        newErrors.email = t("messageForm.invalidEmail");
      }
      if (formData.subject === "") {
        newErrors.subject = t("messageForm.subjectRequired");
      }
      if (formData.message === "") {
        newErrors.message = t("messageForm.messageRequired");
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = t("messageForm.captchaRequired");
      }
      setErrors(newErrors);

      // Function to validate email format
      function isValidEmail(email) {
        // Use a regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      }

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          const response = await axios.post(
            BaseApi + "/page/contact-us",
            formData
          );
          // Reset the reCAPTCHA
          handleRecaptchaReset();
          if (response.data.status === 200) {
            Swal.fire({
              title: t("messageForm.messageSuccessTitle"),
              icon: "success",
              confirmButtonText: t("messageForm.close"),
            });
            setFormData({
              ...formData,
              name: "",
              email: "",
              subject: "",
              message: "",
            });
          } else if (response.data.status === 500) {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: t("messageForm.close"),
            });
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: t("messageForm.close"),
            });
          }
        }
      }
    } catch (error) {
      Swal.fire({
        title: t("messageForm.messageFailedTitle"),
        text: t("messageForm.messageFailedTxt"),
        icon: "error",
        confirmButtonText: t("messageForm.close"),
      });
    }
    console.log(formData);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <NavBar /> */}
      <Header />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className=" accountRegistry servicesTxt m-0">
            {/* <div className="text-center PPSection1">
              <h1 className="">{pageContent.page_title}</h1>
              <h6 className="text-muted fw-normal">
                {" "}
                <Link to="/" style={{ color: "grey" }}>
                  {t("navHeaders.home")}
                </Link>{" "}
                /{pageContent.page_title}
              </h6>
            </div> */}
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="upperPart">
                    <p>
                      {pageContent.page_description &&
                        HTMLReactParser(pageContent.page_description)}
                    </p>
                    <p className="promo-block p-0 bg-white border-bottom-0">
                      <Link to="/contact" class="btn btn-primary">
                        Contact us today for further information
                      </Link>{" "}
                      &nbsp;
                    </p>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="promo-block">
                    <h2>Your hardest working job board!</h2>
                    <p className="text-customize">
                      Find staff quickly and easily, simply register online and
                      enjoy the following benefits:
                    </p>
                    <h3 className="postandmanage">
                      <i class="fa fa-file-text"></i> Post &amp; Manage
                      Vacancies
                    </h3>
                    <p className="text-dark" style={{ lineHeight: "160%" }}>
                      Start targeting job seekers immediately.
                    </p>
                    <h3>
                      <img src="/Images/checked_black.png" alt="icons" />
                      Track your Applications
                    </h3>
                    <p className="text-dark">
                      Each application is saved, enabling you to view the
                      covering letter and applicant details for each vacancy.
                    </p>
                  </div>

                  <div className="promo-block">
                    <h2>Need assistance?</h2>
                    <p className="text-customize">
                      A member of our staff is ready to help you with any
                      questions you may have.
                    </p>
                    <p>
                      <Link to="/contact" className="btn btn-primary">
                        Contact us for a fast response
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default CareerResources;
