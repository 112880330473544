import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Typography } from "@mui/material";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  EmailShareButton,
  InstapaperShareButton,
  TelegramShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
  PinterestIcon,
  InstapaperIcon,
  TelegramIcon,
} from "react-share";
import { useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import HTMLReactParser from "html-react-parser";
import Swal from "sweetalert2";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
const NewJobCard = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null); // Track the selected payment

  const handleOpen = () => {
    // console.log("Clicked payment:", plan); // Add this line
    // setSelectedPayment(plan);
    setOpen(true);
  };
  const [t, i18n] = useTranslation("global");

  const handleClose = () => {
    // setSelectedPayment(null);
    setOpen(false);
  };
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjusted width for mobile responsiveness
    maxWidth: "400px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  let siteLogo = Cookies.get("siteLogo");
  const tokenKey = Cookies.get("tokenClient");
  const curr = Cookies.get("curr");

  // const handleJobView = () => {
  //   if (!tokenKey) {
  //     navigate("/user/jobseekerlogin");
  //   } else {
  //     if (props.itemSearched) {
  //       navigate(
  //         `/newjobdescription/${props.slug}/${props.cat_slug}/${props.itemSearched}`
  //       );
  //     }
  //     navigate(`/newjobdescription/${props.slug}`);
  //   }
  // };

  // let slug = props.slug;

  const handleJobView = async () => {
    console.log(props.type);
    if (props.apply_url !== null) {
      try {
        const response = await axios.post(
          BaseApi + `/job/applyxmljob`,
          { slug: props.slug },
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
      } catch (error) {
        console.log("Couldn't hit applyimportcount job api");
      }
      window.location.href = props.apply_url;
    } else {
      if (!tokenKey) {
        navigate("/user/jobseekerlogin");
      } else {
        if (props.itemSearched) {
          navigate(
            `/jobdescription/${props.slug}/${props.cat_slug}/${props.itemSearched}`
          );
        }
        navigate(`/jobdescription/${props.slug}`);
      }
    }
  };

  const savedJob = async (slug) => {
    try {
      // setLoading(true);
      const response = await axios.post(
        BaseApi + `/job/JobSave/${slug}`,
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );

      // setLoading(false);
      if (response.data.status === 200) {
        props.getData();
        Swal.fire({
          title: t("jobDescription.savedJobSuccessTitle"),
          icon: "success",
          confirmButtonText: t("jobDescription.close"),
        });
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          confirmButtonText: t("jobDescription.close"),
        });
      }
    } catch (error) {
      // setLoading(false);
      Swal.fire({
        title: t("jobDescription.savedJobFailedTitle"),
        icon: "error",
        confirmButtonText: t("jobDescription.close"),
      });
      console.log("Cannot save job!");
    }
  };

  return (
    <div className="job-list">
       {props.logo ? (
          <>
            <img
              src={props.logo}
              alt="company-logo"
              className="img-fluid logoProfile"
            />
          </>
        ) : (
          <></>
          // <Link to="">
          //   <img src={siteLogo} alt="" className="logoProfile m-0" />
          // </Link>
        )}
      <h2>
        {" "}
        
        <Link
          to={`/jobdescription/${props.slug}`}
          // onClick={handleJobView}
        >
          {props.title}{" "}
       
        </Link>{" "}
        <br />
        {props.job_city}
       
      </h2>
       <div className="images-logo">
      
       </div>
        
      <h3>
        <Link
          to={`/companyrelatedjobs/${encodeURIComponent(props.company_name)}`}
        >
          {props.company_name}
        </Link>
      </h3>

      <div className="job-list-buttons">
        <Link
          to={`/jobdescription/${props.slug}`}
          className="btn btn-primary btn-moreInfo"
        >
          More Info
        </Link>

        <button
          // to={`/newjobdescription/${props.slug}/${props.cat_slug}`}
          onClick={handleJobView}
          className="btn btn-primary btn-apply"
        >
          Apply Now
        </button>
        {/* <button
          // to={`/newjobdescription/${props.slug}/${props.cat_slug}`}
          onClick={() => savedJob(props.slug)}
          className="btn btn-primary btn-save"
        >
          Save Job
        </button> */}
        {tokenKey &&
          (props.is_job_saved === 1 ? (
            <button
              // to={`/newjobdescription/${props.slug}/${props.cat_slug}`}
              onClick={() => savedJob(props.slug)}
              className="btn btn-primary btn-save"
              disabled
            >
              Saved
            </button>
          ) : (
            <button
              // to={`/newjobdescription/${props.slug}/${props.cat_slug}`}
              onClick={() => savedJob(props.slug)}
              className="btn btn-primary btn-save"
            >
              Save Job
            </button>
          ))}
        {!tokenKey && (
          <button
            // to={`/newjobdescription/${props.slug}/${props.cat_slug}`}
            onClick={() => navigate("/user/jobseekerlogin")}
            className="btn btn-primary btn-save"
          >
            Save Job
          </button>
        )}

        <Link
          to={`/companyrelatedjobs/${props.company_name}`}
          className="btn btn-default btn-more-jobs"
        >
          more jobs by
          <br />
          this advertiser
        </Link>
      </div>
    </div>
  );
};

export default NewJobCard;
