import React from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import HTMLReactParser from "html-react-parser";

const NewFeaturedJobsCard = (props) => {
  let siteLogo = Cookies.get("siteLogo");

  const getExcerpt = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const excerpt = props.desc ? getExcerpt(props.desc, 20) : "";

  return (
    <>
      <div className="col-md-6">
        <div class="job-list">
          {props.logo ? (
            <Link to="">
              <img src={props.logo} alt="" className="logoProfile m-0" />
            </Link>
          ) : (
            <></>
            // <Link to="">
            //   <img src={siteLogo} alt="" className="logoProfile m-0" />
            // </Link>
          )}

          <h2>
            <Link to={`/jobdescription/${props.slug}/${props.cat_slug}`}>
              {props.title}
            </Link>
          </h2>
          <h3>
            <Link to="">{props.company_name} </Link>
          </h3>
          <p>{HTMLReactParser(excerpt)}</p>
          <p>
            <Link
              to={`/jobdescription/${props.slug}/${props.cat_slug}`}
              className="btn btn-primary btn-apply"
            >
              MORE INFO / APPLY
            </Link>
            <Link
              to={`/companyrelatedjobs/${props.company_name}`}
              className="btn btn-default btn-more-jobs"
            >
              more jobs by
              <br />
              this advertiser
            </Link>{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default NewFeaturedJobsCard;
