import React from 'react'

const BlankPage = () => {

  return (
    <div>
      <h1></h1>
    </div>
  )
}

export default BlankPage










