import React, { useEffect, useState } from "react";
import NavBar from "../element/NavBar";
import Footer from "../element/NewFooter";
import { Link } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import HTMLReactParser from "html-react-parser";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Header from "../element/Header";

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState([]);
  const [t, i18n] = useTranslation("global");
  const currentLanguage = Cookies.get("selectedLanguage") || "en";
  const [pageContent, setPageContent] = useState([]);
  const [loading, setLoading] = useState(false);


  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(BaseApi + `/page/about_us`, {
        language: currentLanguage,
      });
      setLoading(false);
      setAboutUs(response.data.response);
      // Update Meta Data
      // document.title = response.data.response.meta_tile;

      const metaTitle = document.querySelector('meta[name="title"]');
      console.log(metaTitle.content, "metaTitle");
      if (metaTitle) {
        metaTitle.content = response.data.response.meta_tile;
      }

      const metaDescription = document.querySelector('meta[name="description"]');
      console.log(metaDescription.content, "metaDescription");
      if (metaDescription) {
        metaDescription.content = response.data.response.meta_description;
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.content = response.data.response.meta_keyword;
      }
    } catch (error) {
      console.log("Cannot get data about us!");
    }
  };

  // const getData = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.post(
  //       BaseApi + `/page/staticpage/leadership-team`,
  //       { language: currentLanguage }
  //     );
  //     setLoading(false);
  //     setAboutUs(response.data.response);

  //     // Update Meta Data
  //     // document.title = response.data.response.meta_tile;

  //     const metaTitle = document.querySelector('meta[name="title"]');
  //     console.log(metaTitle.content, "metaTitle");
  //     if (metaTitle) {
  //       metaTitle.content = response.data.response.meta_tile;
  //     }

  //     const metaDescription = document.querySelector('meta[name="description"]');
  //     console.log(metaDescription.content, "metaDescription");
  //     if (metaDescription) {
  //       metaDescription.content = response.data.response.meta_description;
  //     }

  //     const metaKeywords = document.querySelector('meta[name="keywords"]');
  //     if (metaKeywords) {
  //       metaKeywords.content = response.data.response.meta_keyword;
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("Cannot get data about us!");
  //   }
  // };

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  useEffect(() => {
    getData();
    // getConstantData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="aboutUs" style={{minHeight : "100vh"}}>
        {/* <NavBar /> */}
        <Header />
        {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
        <div className="newAboutus text-center">
        <h1>
          {/* {aboutUs.page_title ? HTMLReactParser(aboutUs.page_title) : ""} */}
        </h1>
        {/* <h6 className="text-muted fw-normal">
          {" "}
          <Link to="/" style={{ color: "grey" }}>
            {t("navHeaders.home")}
          </Link>{" "}
          /{aboutUs.page_title}
        </h6> */}
      </div>

      <div className="content-general leadership_team">
        <div className="container">
          <div className="col-sm-12">
            <h1>
              {aboutUs.page_title ? HTMLReactParser(aboutUs.page_title) : ""}
            </h1>
            <h2>
              {aboutUs.page_description &&
                HTMLReactParser(aboutUs.page_description)}
            </h2>

            {/* <h1>Leadership team</h1>
            <h2>
              Helping organisations build an inclusive workplace where
              diversity is recognised and respected.
            </h2>
            <hr />
            <h2>
              <img
                src="/Images/pic_libby.jpg"
                alt="our-images-pic"
                className="img-circle"
              />
             Libby Toovey (She/Her) - Lead Consultant
            </h2>
            <p>
            Libby's passion for Indigenous culture goes back as long as she can remember. In school, Libby learnt a very different version of First Nations history in Australia and made it her mission to find out what really happened when Captain Cook arrived. This passion has led Libby to study a major in Indigenous Studies at Monash University during her Undergrad degree and to become a secondary school History teacher, specialising in Australian History, particularly First Nations history. This has included teaching high school students all about the frontier wars and the systematic genocide of Indigenous peoples' during invasion, the continuing impacts of colonisation on Aboriginal and Torres Strait Islanders, the Stolen Generations, Charles Perkins' Freedom Ride in 1965 and the current push for constitutional recognition (#VoteYes). Libby has made sure that every history student she teaches know that Australia wasn't 'settled', it was invaded, and that it Always Was, Always Will Be, Aboriginal Land!
            </p>
            <p className="my-3">
            Libby has also been fortunate enough to travel on Country for secret women's business in Papulankutja (Blackstone), a remote community 900km west of Alice Springs and has been engaged with the Aboriginal community in Naarm through the 'Be Deadly' camp run by Lord Somers Camp and Powerhouse. You can see Libby's passion and advocacy come to life @yes.for.equality on Instagram.
            </p>

            <h3>
              <i class="fa fa-linkedin-square"></i>{" "}
              <Link to="https://www.linkedin.com/in/libby-toovey-9a3947172/" target="_blank">
                LinkedIn
              </Link>
            </h3>
            <hr />

            <h2>
              <img
                src="/Images/pic-jared.jpg"
                alt="our-images-pic"
                className="img-circle"
              />
              Jared Telling - Digital Services Manager
            </h2>
            <p>
            Jared's online experience started back in the days of dialup modems and he hasn't looked back since. During the dotcom boom he was Webmaster for one of the first high profile e-commerce websites theLounge.com.au. In 2001 he moved to London where he worked for one of the UK's leading online digital agencies working with companies such as Disneyland Paris, Dixons, Starbucks, BT (British Telecom) and Marks & Spencer where he eventually became part of the in-house web team working solely on their e-commerce website. In 2004 He then moved to France and setup his own web agency servicing clients locally and also in the UK and Australia.
            </p>
            <p className="my-3">
            He's been involved in web based projects of all scales and sizes, with extensive experience in e-commerce, online recruitment, content management systems, online marketing and digital design. He brings his vast web experience to LGBTI JOBS and he's extremely motivated to assist in building an equal working environment.
            </p>

            <h3>
              <i class="fa fa-linkedin-square"></i>{" "}
              <Link to="https://www.linkedin.com/in/jared-telling-666286" target="_blank">
                LinkedIn
              </Link>
            </h3>
           

            <h2>
            
              Nicolas Le Berre - CIO
            </h2>
            <p>
            After graduating with a Degree in Business, Nicolas made the switch to Web Development where he found his true calling after completing a 2nd degree in Computer Programming. Specialised in all things dynamic, he is particular focused on database integration, Javascript and PHP.
            </p>
            <p className="my-3">
            Together with Jared he is co-director of a web agency and manages the France office. He comes from a web agency background working on clients of all sizes in Paris and Brittany.
            </p>
            <p className="my-3">
            Over the course of the last 15 years Nicolas has been involved in developing bespoke e- commerce web sites, job boards and content management systems. Not only does he bring the technical know how but his marketing and business experience greatly assist in ensuring that web sites not only work but deliver the desired business goals and returns on investment.
            </p>

            <h3>
           
            </h3> */}
          </div>
        </div>
      </div>
      <Footer />
      </>
      )}

       

        
      </div>
    </>
  );
};

export default AboutUs;
